window.LiveElement.Scale.Adaptors.Local = {
    load: function(mode) {
        this.options.scopes = window.LiveElement.Scale.allowableScopes
        if (mode == 'storage') {
            this.options.items = Object.assign(this.options.items, ...this.options.scopes.map(scope => ({[scope]: {}})))
            this.options.last = Object.assign(this.options.last, ...this.options.scopes.map(scope => ({[scope]: 0})))
            this._refresh()
        }
        if (mode == 'compute') {
            this.options.env = this.options.env && typeof this.options.env == 'object' ? this.options.env : {}
            this.options.processors = this.options.processors && typeof this.options.processors == 'object' ? this.options.processors : {}
        }
    }, 
    options: {
        env: {}, 
        processors: {}, 
        scopes: [], 
        items: {}, 
        last: {}, 
        period: 10000
    }, 
    retrieveItem: function(scope, key) {
        return window.Promise.resolve(window.atob((this.options.items[scope][key]||{}).value || ''))
    }, 
    sendItem: function(scope, key, body) {
        this.options.items[scope][key] = {value: window.btoa(body), timestamp: window.Date.now()}
        return window.Promise.resolve()
    }, 
    runProcessor: function(name, input) {
        return window.Promise.resolve(typeof this.options.processors[name] == 'function' ? this.options.processors[name](input, this.options.env) : undefined) 
    }, 
    _listItemsSince: function(scope, timestamp=0) {
        let now = window.Date.now()
        timestamp = timestamp > 0 ? timestamp : now + timestamp
        this.options.last[scope] = now
        return Promise.resolve(Object.assign({}, ...Object.entries(this.options.items[scope]).filter(entry => entry[1].timestamp >= timestamp).map(entry => ({[entry[0]]: entry[1]}))))
    }, 
    _refresh: function() {
        let $this = this, p = []
        $this.options.scopes.forEach(scope => {
            p.push($this._listItemsSince(scope, this.options.last[scope] || ($this.options.period * -1)).then(items => {
                Object.keys(items).forEach(key => {
                    window.LiveElement.Scale.sync(scope, key)
                })
            }))
        })
        return window.Promise.all(p).then(() => {
            if ($this.options.period) {
                window.setTimeout(function() {
                    $this._refresh()
                }, $this.options.period)
            }
        })
    }
}

/*

window.LiveElement.Scale.storageAdaptor = window.LiveElement.Scale.Adaptors.Local
window.LiveElement.Scale.storageAdaptor.load('storage')
window.LiveElement.Scale.computeAdaptor = window.LiveElement.Scale.Adaptors.Local
window.LiveElement.Scale.computeAdaptor.load('compute')

*/